/** Header begin **/
#header-account {
  background-image: url('../img/home/Header-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 90px;
  padding: 10px;
}

#header-account div.loginBtn button {
  width: 97px;
  height: 36px;
  font-size: 16px;
}

/** Header begin **/
#signin {
  background-color: #212d42;
  height: 300px;
}

#signin .section-title {
  font-size: 30px;
  color: white;
}

#signin p {
  color: white;
  padding-right: 15px;
}

#signin form {
  margin: 0px;
}

#signin div.loginBtn button {
  width: 97px;
  height: 36px;
  font-size: 16px;
}
/** Header end **/

/** Signin/Signup begin **/
#signup {
  background-color: #212d42;
  height: 400px;
}

#signup .section-title {
  font-size: 30px;
  color: white;
}

#signup p {
  color: white;
  padding-right: 15px;
}

#signup h5 {
  margin: 0px;
  padding-bottom: 20px;
}

#signup form{
  margin: 0px;
  padding: 10px;
  border: 25px solid #212d42;
}

#singup div.input-field {
  padding: 10px;
}



#alert_message {
  background-image: url('../img/home/alert_yellow_icon.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-color: #212d42;
  border: 25px solid #212d42;
  padding-top: 60px;
}

#alert_message p {
  color: #FFCD30;
  margin-bottom: 0px;
}
/** Signin/Signup end **/

#footer-simple {
  background-image: url('../img/home/CCCupLogo.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 50%;
  background-color: #212d42;
  min-height: 100vh;
}

/** Generic styling Begin **/

.form-input{
  font-size: 14px !important;
  color: #212d42 !important;
}

.form-fields {
  margin: 0px;
  padding: 0px;
}

.form-row {
  flex-wrap: wrap;
  height: 50px;
  padding-right: 15px;
  box-sizing: border-box;
}

.form-row input {
  height: 35px;
  box-sizing: border-box;
}

.form-row .inputlong {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
}

.form-row .inputthird {
  width: 60%;
  padding: 6px 6px;
  margin: 8px 0;
}

.form-row .inputmed {
  width: 25%;
  padding: 12px 20px;
  margin: 8px 0;
}

/* form-light style start */
.form-light {
  height: auto;
}

.form-light-header {
  border-bottom: none;
  align-items: center !important;
  padding: 25px 25px 0 25px !important;
}

.form-light-header h5{
    color: #212d42;
    font-size: 24px;
}

.form-light-body {
  padding: 15px 25px 15px 25px ;
}

.form-light-body label {
  color: #212d42;
  padding: 10px;
  display: block;
  font-size: 20px;
}

.form-light-footer {
  border-top: none;
  padding: 0px 25px 25px 25px;
}


.form-light p{
  color: #212d42;
  font-size: 16px;
}

#form-light div.loginBtn button {
  width: 97px;
  height: 36px;
  font-size: 16px;
}

.form-light .name {
  color: #7a89a5;
  font-size: 14px;
  padding: 10px 0;
}

.add-new-item-form-light {
  max-width: 1000px;
}
/* form-light style end */

#ccbutton {
  width: 97px;
  height: 36px;
  font-size: 16px;
}

/** Generic styling End **/

/** Create Cafe styling Begin **/
#createCafe div.loginBtn button {
  width: 97px;
  height: 36px;
  font-size: 16px;
}