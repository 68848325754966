/* Common START */

body {
    /* width: 1920px; */
    width: 100%;
}

.myContainer {
    max-width: 1280px;
    margin: 0 auto !important;
}
button.myButton {
    border: 2px solid white;
    border-radius: 5px;
    background: transparent;
    color: white;
    cursor: pointer;
    outline: none;
    position: relative;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s ease all;

}
button.bondiBlueBg:hover {
    border: 2px solid white;
    background: transparent;

}

/* outline button */
button.bondiBlueBg {
    border: none;
    background: #00a6b4;
    width: 200px; 
    height: 50px;
    font-size: 18px;
}
.middleGray {
    color: #738086;;
}
.lowGray {
    color: #d0d6da
}
.font14_500{
    font-size: 14px;
    font-weight: 500;
}
.font18_500 {
    font-size: 18px;
    font-weight: 500;
}
i {
    cursor: pointer;
}
div.JB {
    width: 50px;
    height: 50px;
    background: #20b1bd;
    border-radius: 100%;
    font-size: 34px;
    color: white;
    text-align: center;
    margin-right: 20px;
}
table tr td{
    padding: 4px 0;
}
div.caffe{
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
}
div.joe{
    font-size: 20px;
}
/* Common START */


/** Header Start*/
#header nav.navbar {
    padding: 30px 0px!important;
    background-color: unset;
    box-shadow: none;
}
#header .dashboard {
    background-image: url('../img/home/product-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 604px;
    border-radius: 20px;
    -webkit-box-shadow: 0px -5px 19px -5px #000000; 
    box-shadow: 0px -5px 19px -5px #000000;
}
#header {
    background-image: url('../img/home/Header-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 710px;
}
#header a {
    color: white
}

#header span#logo {
    margin-right: 240px;
}
#header span#logo a{
font-size: 42px;
}
#header li a {
    font-size: 22px;
}
#header li a:hover, #header li a:focus, #header li.active a {
    color: #07e4f7;
    background-color: transparent;
}
#header li a:after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background-color: #00a6b4;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
#header li a:hover:after {
    width: 100%;
}
#header li a:active:after, #header li a:focus:after {
    width: 100%;
}
#header div.loginBtn {
    margin-left: 116px;
}
#header div.loginBtn button {
    width: 97px;
    height: 36px;
    font-size: 16px;
}



#header div.growText div{
    color: white;
    line-height: 1;
}

#header div.growText div.grow{
    font-size: 44px;
    margin-top: 100px;
}
#header div.growText div.leading{
    font-size: 24px;
    margin-top: 44px;
}
#header div.growText div.getBtn{
    margin-top: 44px;
}
#header div.growText div.getBtn button{
    width: 187px;
    height: 45px;
    font-size: 20px;
}

#header div.row {
    margin-top: 30px;
}
/** Header end **/


/** Section 1 Start **/
#section1 {
    margin-top: 100px;
}
.Inventory{
    color: black;
    font-size: 45px;
    margin-top: 60px;
    font-weight: 500;
}
.sell{
    font-size: 20px;
    margin-top: 20px;
    width: 70%;
}
.better{
    margin-top: 50px;
    font-size: 18px;
}

.better div {
    margin: 9px 0;
}
img.window {
    margin-right: 30px;
    width: 34px;
    cursor: pointer;
}


#section1 .inventoryList button {
    width: 200px;
    font-size: 16px;
    height: 37px;
}
#section1 .inventoryList button:hover {
    color: #00a6b4;
    border: 2px solid #00a6b4;
}

#section1 div.propertyBak{
    background-image: url('../img/icons/property-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 23px;
    margin-right: 20px;
}
#section1 div.editPenBak {
    background-image: url('../img/icons/edit-pen-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 19px;
    height: 19px;
}
/** Section 1 End **/


/** Section 2 Start **/
#section2 {
    max-width: 1400px;
    margin: 40px auto;

}
#section2 div.currentShopping button {
    color: #00a6b4;
    border-color: #00a6b4;
    font-size: 18px;
    width: 147px;
    height: 38px;
}
#section2 button.urgentBtn {
    color: rgb(247, 138, 152);
    font-weight: 500;
    background: rgb(253, 222, 226);
    height: 34px;
    font-size: 14px;
    width: 74px;
}
#section2 button.lowBtn {
    color: rgb(58, 179, 110);
    font-weight: 500;
    background: rgb(224, 243, 232);
    height: 34px;
    font-size: 14px;
    width: 74px;
}
/** Section 2 End **/


/** Section 3 Start **/
#section3 {
    text-align: center;
}
#section3 .sell2 {
    font-size: 20px;
    margin-top: 20px;
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
}
#section3 div.productDetailImg{
    position: relative;
    background-image: url('../img/home/product-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 860px;
    height: 604px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 20px;
    -webkit-box-shadow: 0px -5px 19px -5px #000000; 
    box-shadow: 0px -5px 19px -5px #000000;
    z-index: 2000;
}
/** Section 3 End **/



/** Feedback Start **/
#feedback {
    position: relative;
    background-image: url('../img/home/Header-bg.png');
    background-repeat: no-repeat;
    height: 570px;
    margin-top: -90px;
    padding-top: 150px;
}
#feedback .what {
    padding: 30px 0 30px 0;
    color: white;
    font-size: 32px;
    text-align: center;
    width: 100%;
    font-weight: 600;
}
#feedback .why {
    /* padding: 150px 0 30px 0; */
    color: white;
    font-size: 16px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    padding-bottom: 40px;
}
#feedback .replyCenter {
    padding: 0 60px;
}
#feedback .replyArrow {
    margin-left: auto;
}
#feedback .reply{
    text-align: center;
}
#feedback .reply em {
    color: white;
    font-size: 20px;
    font-weight: 500;
}
#feedback .katie, #feedback .culinary{
    color: rgb(208 203 203);
    font-size: 14px;
    text-align: center;
    margin-top: 27px;
}
#feedback .circle3 {
    color: white;
    font-size: 24px;
    text-align: center;
    margin-top: 42px;
}
#forwardArrow {
    cursor: pointer;
}
/* Feedback End */



/* Footer Start */
#footer {
    padding: 50px 0;
}
#footer a.fa {
    border: 2px solid #20b1bd;
    color: #20b1bd;
    padding: 10px;
    font-size: 30px;
    width: 54px;
    border-radius: 50%;
    text-decoration: none;
    margin: 5px 10px;
}

#footer div.copyright {
    padding-left: 40px;
}

#footer div.socialIcons {
    float: right;
    padding-right: 32px;
}
/* Footer End */


/* Carousel */

.carousel-indicators {
    top: 120% !important;
}

.carousel-indicators li {
    margin: 0 10px !important;
    border: none !important;
    background: #717b9f !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
}

.carousel-indicators li.active {
    background: #20b1bd !important;
}

.carousel-control {
    background: transparent !important;
    display: flex;
    width: 90px !important;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}

.carousel-inner {
    width: 90% !important;
    margin: 0 auto;
}

.carousel-control span {
    font-size: 50px;
    width: 50px;
    height: 64px;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.img-round {
    border-radius: 20px;
}

.text-lg {
    font-size: 25px;
}

section {
    padding-left: 30px;
    padding-right: 30px;
}

section h1 {
    font-size: 36px;
}

section p {
    font-size: 20px;
}

img.box-shadow {
    box-shadow: 0 10px 20px 8px rgba(0,0,0,0.2);
}

.close-btn {
    font-size: 40px;
    color: white;
}

@media (max-width: 600px) { 
    section {
        padding-left: 20px;
        padding-right: 20px;
    }
    h1 {
        font-size: 30px !important;
    }
    p {
        font-size: 16px !important;
    }
    #header {
        background-size: 100% 90%;
        height: 700px !important;
    }
    #section1 {
        margin-top: 50px !important;
    }
    .better {
        margin-left: 0 !important;
    }
    #feedback .replyCenter {
        padding: 0 !important;
    }
    .xs-center {
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .carousel-indicators {
        top: 105% !important;
    }
    #feedback {
        min-height: 700px;
        padding-top: 100px !important; 
    }
    .nav {
        background-color: unset;
    }
    .navbar {
        flex-wrap: nowrap;
    }
    .navbar-brand h1 {
        margin-right: 0 !important;
    }
    .loginBtn {
        text-align: center;
        margin-left: 0 !important;
    }
    #navbarNav {
        position: fixed;
        top: 0;
        left: 0;
        padding: 30px;
        height: 100vh;
        width: 100%;
        background: #1f2f4e;
        z-index: 999;
    }
}