/* shopping list button */
.urgent-btn{
    border: none;
    color: #ef233c;
    background-color: #fddee2;
    font-size: 14px;
    padding: 4.5px 0px;
    border-radius: 3px;
    cursor: pointer;
    width: 71px;
    text-transform: uppercase;
}

.low-btn{
    border: none;
    color: #2eae65;
    background-color: #e0f3e8;
    font-size: 14px;
    padding: 4.5px 0px;
    border-radius: 3px;
    cursor: pointer;
    width: 71px;
    text-transform: uppercase;

}

.table .shopping-arrow {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: #b6becc;
}
/* list button end */

.shopping-list tr td:nth-child(3){
    display: flex;
    justify-content: space-between;
}

.shopping-list-block{
    height: 410px;
    overflow: hidden;
    overflow-y: auto;
}

/* scrollbar customize */
.shopping-list-block::-webkit-scrollbar {
    width: 10px;
}

.shopping-list-block::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1.5px #c0c3c9; 
    border-radius: 6px;
    height: 10px;
}
   
.shopping-list-block::-webkit-scrollbar-thumb {
    background: #c6ccd7; 
    border-radius: 10px;
}

.shopping-list-block::-webkit-scrollbar-thumb:hover {
    background: #979ca5; 
}
/* scrollbar customize end */

/* shopping for */

.inventory-list tr td:first-child label{
    font-size: 15px !important;
    font-weight: 600;
    color: #212d42;
    display: flex;
    align-items: center;
}
.border-none {
    border: none;
    background-color: transparent;
}
.inventory-list .list-chk{
    padding-left: 65px;
}

.card .inventory-list thead tr th label 
{
    font-size: 14px !important;
    font-weight: 600;
    color: #b6becc !important;
    display: flex;
    align-items: center;
}

.price-table thead tr th, 
.price-table tbody tr td {
    text-align: center;
}
.price-table thead tr th:first-child{
    width: 10%;
}
.price-table thead tr th:nth-child(2) {
    width: 60%;
}
/* Strawberries modal */
.modal .close-btn{
    color: #7a89a5;
    background-color: #f2f4f8;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 22px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    width: 125px;
    height: 40px;
}
/* Strawberries modal end */

/* Shopping_list_price_update page */
.shopping-price-update tr td:nth-child(3){
    display: flex;
    justify-content: space-between;
}

/* shopping trip modal */
#shoppingTrip .table{
    margin-bottom: 0px !important;
}

#shoppingTrip .table thead tr th {
    font-size: 14px;
    font-weight: 600;
    color: #b6becc;
}

#shoppingTrip .table tbody tr td {
    font-size: 15px;
    color: #7a89a5;
    font-weight: 600;
    padding: 15px !important;
}

#shoppingTrip .table tbody tr td:nth-child(1) {
    color: #212d42;
}
/* shopping trip modal end */

/* checkbox customize */
.list-chk:hover input ~ .checkmark {
    background-color: #ccc;
}

.list-chk {
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}
    
.list-chk input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}
    
.checkmark, .link {
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
border: 1px solid #7a89a5;
border-radius: 3px;
}
    
.circle {
border-radius: 50%;
}
    
.list-chk input:checked ~ .checkmark {
background-color: #00a6b4 !important;
border: #00a6b4;
}
    
.checkmark:after {
content: "";
position: absolute;
display: none;
}

.list-chk input:checked ~ .checkmark:after {
display: block;
}

.list-chk:hover input ~ .checkmark {
background-color: white;
}

.list-chk .checkmark:after {
left: 10px;
top: 3px;
width: 5px;
height: 15px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.list-chk .circle:after {
width: 15px;
height: 15px;
border: none;
background: white;
border-radius: 50%;
left: 20%;
top: 20%;
}
/* checkbox customize end */

/* Products page */
.products .form-group{
    padding-left: 0px!important;
}

.products .add-item-label{
    color: #b6becc !important;
    font-size: 14px !important;
    font-weight: 600;
}

/* Products detail page */
.description-title{
    color: #b6becc !important;
    font-size: 14px !important;
    font-weight: 600;
    margin-top: 15px;
}

.product-description, .product-detail-table tbody tr td{
    font-size: 14px;
    font-weight: 600;
    color: #3b414c;
}

.product-detail-table{
    width: 30%;
}

.product-detail-table thead th{
    font-size: 14px;
    font-weight: 600;
    color: #b6becc;
}

.price-history tbody tr td:last-child{
    color: #00a6b4;
}

/* product detail edit page */
.product-size-upc label{
    font-size: 14px !important;
    font-weight: 600;
    color: #b6becc !important;
}

/* add new product page */

.price-table1 {
    margin-left: 2px;
} 
@media (min-width: 576px){
    #NewProduct .modal-dialog ,#addProCafe .modal-dialog ,#addItem .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }

    .price-table1 {
        margin-left: 20px;
    } 

    #proLookup .modal-dialog{
        max-width: 850px;
    }
}

/* product cafe */
.product-edit-del {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-del-btn a{
    color: #00a6b4;
}

.product-del-btn img{
    width: 20px;
    height: 20px;
}

.product-del-btn a{
    display: flex;
    align-items: center;
}
/* inventory items */
.add-upc-pro{
    color: #00a6b4;
    font-size: 16px;
    margin: 5px 15px;
}

.add-upc-pro:hover{
    color: #00a6b4;
}

/* proLookup modal */
#proLookup .table thead tr th {
    font-size: 14px;
    font-weight: 600;
    color: #b6becc;
}
#proLookup  .table tbody tr td {
    font-size: 15px;
    color: #b6becc;
    font-weight: 600;
    padding: 15px !important;
}
#proLookup .table tbody tr td:nth-child(1) {
    color: #212d42;
}

.height-inherit{
    height: inherit;
}

/* EditableDetails style */
.detail-title{
    margin: 0;
    color: #b6becc;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
}
.detail-body{
    margin: 0;
    color: #212d42;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
    padding-bottom: 12px;
}