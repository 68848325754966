/* icon style start*/
.dashboard-icon {
    background:url('../img/icons/dashboard-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 28px;
}

/* .sidebar .nav > .nav-item > a:hover .dashboard-icon, .sidebar .nav > .nav-item a:focus .dashboard-icon {
    background: url('../img/icons/home - hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
} */

.shopping-icon {
    background:url('../img/icons/shopping-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 25px;
}

/* .sidebar .nav > .nav-item > a:hover .shopping-icon , .sidebar .nav > .nav-item a:focus .shopping-icon  {
    background: url('../img/icons/shopping - hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
} */

.inventory-icon {
    background:url('../img/icons/inventory-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 25px;
}

/* .sidebar .nav > .nav-item > a:hover .inventory-icon , .sidebar .nav > .nav-item a:focus .inventory-icon  {
    background: url('../img/icons/inventory - hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
} */

.items-icon {
    background:url('../img/icons/items-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 28px;
}

/* .sidebar .nav > .nav-item > a:hover .items-icon, .sidebar .nav > .nav-item a:focus .items-icon {
    background:url('../img/icons/items-hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
} */


.products-icon {
    background:url('../img/icons/products-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 28px;
}

.supplier-icon {
    background:url('../img/icons/supplier-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 28px;
}

/* .sidebar .nav > .nav-item > a:hover .supplier-icon , .sidebar .nav > .nav-item a:focus .supplier-icon  {
    background: url('../img/icons/supplier - hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
} */

.edit-pen-icon {
    background: url('../img/icons/edit-pen-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 18px;
}

.property-icon {
    background: url('../img/icons/property-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 20px;
}

.logout-icon {
    background: url('../img/icons/logout-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 25px;
}

.menu-icon {
    background: url('../img/icons/menu-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 20px;
}

.menu--white-icon {
    background: url('../img/icons/menu-white.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 35px;
}

.delete-icon {
    background: url('../img/icons/delete-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 35px;
    height: 25px; 
}

.delete-hover-icon {
    background: url('../img/icons/delete-hover-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 35px;
    height: 25px; 
}
/* icon style end*/

/* button style start */
.long-btn {
    padding: 6px 40px !important;
}

.table .blue-btn {
    font-size: 14px !important;
    height: 35px;
}

.blue-btn {
    color: white;
    background-color: #00a6b4;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid #00a6b4;
}

.blue-btn:hover {
    color: #00a6b4;
    border: 2px solid #00a6b4;
    background-color: white;
}

.outline-blue-btn {
    color: #00a6b4;
    border: 2px solid #00a6b4;
    background-color: white;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.outline-blue-btn:hover {
    color: white;
    background-color: #00a6b4;
}

.inventory-btn-group button:nth-child(1){
    margin-right: 20px;
}

.primary-contained-btn {
    outline: none;
    color: #7a89a5;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #f2f4f8;
    background-color: #f2f4f8;
}

.primary-contained-btn:hover {
    background-color: white;
}

.pink-btn{
    border:  1px solid #fddee2;
    color: #ef233c;
    background-color: #fddee2;
    font-size: 14px;
    padding: 4.5px 0px;
    border-radius: 3px;
    cursor: pointer;
    width: 71px;
    text-transform: uppercase;
}

.pink-btn:hover {
    border: 1px solid #fddee2;
    background-color: white;
}

.light-green-btn{
    border: 1px solid #e0f3e8;
    color: #2eae65;
    background-color: #e0f3e8;
    font-size: 14px;
    padding: 4.5px 0px;
    border-radius: 3px;
    cursor: pointer;
    width: 71px;
    text-transform: uppercase;
}

.light-green-btn:hover {
    border: 1px solid #e0f3e8;
    background-color: white;
}

.light-yellow-btn {
    border: 1px solid  #fff0e0;
    color: #ff9931;
    background-color: #fff0e0;
    font-size: 14px;
    padding: 4.5px 0px;
    border-radius: 3px;
    cursor: pointer;
    width: 71px;
    text-transform: uppercase;
}

.light-yellow-btn:hover {
    border: 1px solid  #fff0e0;
    background-color: white;
}

.icon-btn {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
}

.icon-btn img {
    width: 30px;
}

.outline-icon-btn {
    background-color: transparent;
    border: 2px solid #00a6b4;
    border-radius: 5px;
    padding: 0 15px;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
}

button {
    cursor: pointer;
}
/* button style end */


/* Modal style start */
.modal-dialog {
    height: 100vh;
}

.modal-header {
    border-bottom: none;
    align-items: center !important;
    padding: 25px 25px 0 25px !important;
}

.modal-body {
    padding: 15px 25px 15px 25px ;
}

.modal-footer {
    border-top: none;
    padding: 0px 25px 25px 25px;
}

.modal-title {
    font-size: 26px;
    font-weight: 600;
    color: #212d42;
}

.form-group label {
    color: #7a89a5 !important;
}

.modal .close {
    font-size: 45px;
    font-weight: 100;
    color: #212d42 !important;
    opacity: 1 !important;
    padding: 10px 5px;
}

.modal-footer>:not(:last-child) {
    margin-right: 10px;
}

.modal p{
    color: #212d42;
    font-size: 16px;
}
.modal .name {
    color: #7a89a5;
    font-size: 14px;
    padding: 10px 0;
}

.add-new-item-modal {
    max-width: 1000px;
}

.add-new-item-modal .table tr th,
.add-new-item-modal .table tr td {
    width: 85%;
} 

.detail-item-modal {
    max-width: 500px;
}

.detail-item-modal .table tr th,
.detail-item-modal .table tr td {
    width: 50%;
} 
@media (max-width: 768px){
    .main-panel .page-header1 {
        flex-direction: row !important;
    }
}
@media (min-width: 991px) { 

    .add-new-inventory-modal {
        max-width: 1000px;
        width: 100%;
    }
    
    .add-new-inventory-modal .table tr th,
    .add-new-inventory-modal .table tr td {
        width: 100%;
        width: 100%;
    }
    
    .add-suppplier-shopping-modal {
        max-width: 600px;
        width: 60%;
    }
    
    .add-suppplier-shopping-modal .table tr th,
    .add-suppplier-shopping-modal .table tr td {
        width: 100%;
        width: 100%;
    } 
}
/* Modal style end */

.text-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.table tr{height:100% !important;}
.table td{height:100% !important;}



.main-header .name-text p:nth-child(1){
    color: #212d42;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.main-header .name-text p:nth-child(2){
    color: #73829a;
    font-size: 14px;
    margin-bottom: 0px;
}

.sides-view{
    display: flex;
    flex-direction: column;
}
.flex-full{
    flex: 1;
}

/* Card style start */
.card {
    padding: 20px 20px 20px 30px;
}

.card .card-header,
.card .card-body {
    padding: 0px;
}

.table thead tr th 
{
    font-size: 14px;
    font-weight: 600;
    color: #b6becc;
}

.table thead th {
    border-bottom-width: 1px;
    padding: 15px !important;
}

.card .table tbody tr td {
    font-size: 15px;
    color: #7a89a5;
    font-weight: 600;
    padding: 15px !important;
}

.table tbody tr td:nth-child(1) {
    color: #212d42;
    font-size: 15px;
    font-weight: 600;
}

.card .card-body .name {
    color: #7a89a5;
    font-size: 14px;
    padding: 10px 0;
}

.card .card-body .description {
    color: #212d42;
    font-size: 14px;
    padding-bottom: 40px;
}



/* Card style end */

.conduct-list-table thead tr th:nth-child(2), 
.conduct-list-table tbody tr td:nth-child(2){
    width: 30%;
    min-width: 200px;
}

.conduct-list-table tbody tr td:nth-child(2) span:nth-child(1) {
    margin-right: 20px;
}


.conduct-list-table tbody tr td:nth-child(2) button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: #b6becc;
}

.history-list-table thead tr th:nth-child(3), 
.history-list-table tbody tr td:nth-child(3){
    width: 5%;
    min-width: 10px;
}
/* 
.history-list-table tbody tr td:nth-child(2) span:nth-child(1) {
    margin-right: 20px;
} */
.history-list-table tbody tr td:nth-child(2){
    width: 25%;
    align-items: center;
}
.history-list-table thead tr th:nth-child(2),
.history-list-table tbody tr td:nth-child(2){
    text-align: center;
}
.history-list-table tbody tr td{
    justify-content: space-between;
}


.history-list-table tbody tr td:nth-child(3) button {
    border: none;
    outline: none;
    justify-content: flex-end;
    background-color: transparent;
    cursor: pointer;
    color: #b6becc;
}

.inventory-log-talbe tbody tr td:last-child,
.inventory-log-talbe thead tr th:last-child {
    width: 200px;
}

.inventory-log-talbe tbody tr td:last-child {
    align-items: center;
}

.inventory-log-talbe tbody tr td:last-child span {
    margin-right: 60px;
}

.inventory-log-talbe tbody tr td:last-child div {
    margin-left: 20px;
    cursor: pointer;
}

.short-input {
    border-color: #ebedf2;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: .25rem;
    width: 50px;
    text-align: center;
    padding: 5px 10px;
    width: 100px;
    height: 35px;
    color: #212d42;
    font-size: 15px;
    font-weight: 600;
}

.short-input:focus {
    border-color: #00a6b4;
}

a, a:hover, a:focus {
    text-decoration: none;
}

.form-control {
    font-size: 14px !important;
    color: #212d42 !important;
}

.inventory-input-group {
    column-gap: 15px;
}

.inventory-input-group select {
    width: 200px;
    height: 100% !important;
}

.inventory-input-group input {
    max-width: 500px;
}

.table-border-line {
    border: 1px solid #b6becc;
    padding: 10px 20px;
}

.two-select-group {
    column-gap: 15px;
}

.two-select-group select {
    height: initial !important;
    width: 180px;
}

.sidebar .nav > .submenu .show a {
    padding: 10px 0px 10px 50px;
}


/* --------- Supplier Page ------------ */

.supplier-page .item {
    margin-right: 30px;
}

.supplier-page .item .title{
    font-weight: bold;
    font-size: 14px;
    color: #b6becc; 
}

.supplier-page .item .content {
    font-weight: bold;
    font-size: 14px;
    color: #dae3f5;
}

.text-black {
    color: #212d42 !important;
}

.shopping-list-block          { overflow-y: auto; height: 100px; }
.shopping-list-block thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background: white; }

/* Mobile View */

.mobile-view {
    padding: 0;
    margin-top: 10px;
    border-top: 1px solid #eee;
}
.mobile-view li {
    list-style: none;
    padding: 10px 0;
}
.mobile-view li p.title {
    color: black;
    font-size: 15px;
    margin-bottom: 0;
}
.mobile-view li p.content {
    margin-bottom: 0;
    color: #7a89a5;
    font-size: 15px;
}
.mobile-view li p.content span {
    margin-right:10px;
}
.mobile-view li:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.mobile-view li button {
    padding: 2px !important;
}

.mobile-view li .list-chk .checkmark:after {
    left: 8px;
    top: 2px;
}

.mobile-view li span {
    font-size: 14px;
}

.new-item-filter {
    padding: 0;
}
.new-item-filter li{
    display: inline-block;
    list-style: none;
    padding: 0 10px;
    font-size: 14px;
    color: #00a6b4;
}

.new-item-filter li.active {
    color: #575962;
}
.new-item-filter li:not(:last-child) {
    border-right: 1px solid #eee;
}

.fix-close-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    background: white;
}

a {
    color: #00a6b4 !important;
    font-size: 15px;
}

.btn-transparent {
    background: transparent;
    color: #00a6b4;
    font-size: 14px;
    border: none;
    height: 30px;
}

.sidebar .logo-header {
    padding-top: 20px;
    display: flex !important;
    justify-content: space-between;
}

.sidebar .logo-header a {
    padding-left: 20px;
    color: white !important;
    font-size: 25px;
}

@media (max-width: 991px) { 
    .sides-view {
        padding-bottom: 65px !important;
    }
}
@media (max-width: 991px) { 
    .main-header {
        position: relative;
    }
    .navbar-header {
        position: relative !important;
        transform: none !important;
        text-align: left;
    }
    .navbar-header .navbar-nav {
        justify-content: flex-start;
    }
    .logo-header {
        display: none !important;
    }
    .nav_open .main-panel, .nav_open .main-header{
        transform: none !important;
    }
        
    .collapse.show {
        transform: translate3d(0%, 0, 0) !important;
        display: block;
    }
    .name-text {
        flex: 1;
    }
    .sidebar {
        z-index: 9999;
        width: 100%;
        transform: translate3d(-100%, 0, 0) !important;
    }
    .main-panel > .content {
        margin-top: 0px;
    }
    .sidebar .sidebar-wrapper {
        width: 100%;
        height: 100%;
    }
}

.custom-toggle {
    background: transparent !important;
    border: none !important;
}

.text-primary {
    color: #00a6b4 !important;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.width-minor {
    width: 50%;
}


